<template>
	<v-container fluid class="pa-0">
		<v-toolbar flat dense dark :style="stickyTop" extension-height="4">
			<v-toolbar-title>{{ $t("BTW Listing") }} </v-toolbar-title>
			<template #extension v-if="loading">
				<v-progress-linear bottom indeterminate color="white"></v-progress-linear>
			</template>
		</v-toolbar>
		<v-container>
			<v-row justify="center">
				<v-col cols="12" md="10">
					<v-card flat outlined>
						<v-data-table disable-sort disable-pagination hide-default-footer :items="vatListings"
							:headers="headers" single-expand show-expand :expanded.sync="expanded"
							v-on:item-expanded="getPdf" item-key="aangiftenr" expand-icon="mdi-file-document-outline">
							<template v-slot:[`item.vandatum`]="{ item }">
								{{ $d(new Date(item.vandatum), "short") }}
							</template>
							<template v-slot:[`item.totdatum`]="{ item }">
								{{ $d(new Date(item.totdatum), "short") }}
							</template>
							<template v-slot:[`item.netto`]="{ item }">
								{{ $n(item.netto, "ccy") }}
							</template>
							<template v-slot:[`item.btw`]="{ item }">
								{{ $n(item.btw, "ccy") }}
							</template>
							<template v-slot:expanded-item="{ headers, item }">
								<td :colspan="headers.length">
									<pdf :src="src" class="ma-3" v-if="item.document_usds_id" />
									<p v-else>
										Het document is niet beschikbaar
									</p>
								</td>
							</template>
						</v-data-table>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
	</v-container>
</template>

<script>
import caas from "@/services/caas";
import pdf from "vue-pdf";
import { useSessionStore } from "@/store/session";
export default {
	name: "VatListing",
	components: { pdf },
	computed: {
		stickyTop() {
			return {
				position: "sticky",
				top: this.$vuetify.application.top + "px",
				zIndex: 1,
			};
		},
	},
	data() {
		return {
			expanded: [],
			loading: false,
			vatListings: [],
			headers: [
				{ text: "Aangiftenr", value: "aangiftenr", width: "100px" },
				{ text: "Van datum", value: "vandatum", width: "150px" },
				{ text: "Tot datum", value: "totdatum", width: "150px" },
				{ text: "Netto", value: "netto", align: "end" },
				{ text: "Btw", value: "btw", align: "end" },
				{ text: "Bevestiging ontvangst", value: "data-table-expand" },
			],
			src: null,
			showPdf: false,
			sessionStore: useSessionStore()
		};
	},
	methods: {
		async fetchVatListing() {
			const sdoName = "boek/objects/dBtwlh.r";
			let params = {
				action: "read",
				nbrOfRows: 10,
				setOpenQuery: `for each btwlh no-lock indexed-reposition`,
				setQuerySort: "by aangiftenr descending",
			};

			this.loading = true;
			const response = await caas.sdo(sdoName, params);
			this.vatListings = response.data.data.RowObject;
			this.loading = false;
		},
		async getPdf(obj) {
			if (obj.value) {
				if (obj.item.document_usds_id) {
					this.loading = true;
					let params = {
						usds_id: Number(obj.item.document_usds_id),
					};
					caas.rpc("getBLOB", params).then((response) => {
						const binaryString = window.atob(
							response.data.blob
						);
						const len = binaryString.length;
						const bytes = new Uint8Array(len);
						for (let i = 0; i < len; ++i) {
							bytes[i] = binaryString.charCodeAt(i);
						}
						this.src = bytes; //new Blob([bytes], { type: "application/pdf" });
						this.loading = false;
						this.showPdf = true;
					});
				}
			} else {
				this.showPdf = false;
				this.src = null;
			}
		},
	},
	mounted() {
		this.fetchVatListing();
	},
};
</script>
